<template>
  <div class="contentView boxS conterntBox">
    <div class="centerView listBox">
      <a-row :gutter="[15, 15]">
        <a-col :xs="24" :sm="24" :lg="6" :xl="6" :xxl="4">
          <w_select_slot>
            <template #content>
              <a-select :placeholder="t('selectAnchor')" style="width: 100%" v-model:value="queryData.influencerAccountIdCode" @change="changeInfluencer" allowClear size="large">
                <a-select-option :value="item.refCode" v-for="(item, index) in influencerData" :key="index">
                  {{ item.lastName }}
                </a-select-option>
              </a-select>
            </template>
          </w_select_slot>
        </a-col>
        <a-col :xs="24" :sm="24" :lg="6" :xl="6" :xxl="4">
          <w_select_slot>
            <template #content>
              <a-input v-model:value="queryData.asin" :placeholder="t('asin')" size="large" allowClear />
            </template>
          </w_select_slot>
        </a-col>
        <a-col :xs="24" :sm="24" :lg="6" :xl="6" :xxl="4">
          <w_select_slot>
            <template #content>
              <a-range-picker :value="date" :allowClear="false" @change="panelChange" :placeholder="[queryData.beginTime, queryData.beginTime]" />
            </template>
          </w_select_slot>
        </a-col>
        <div style="margin: 5px 16px 0" class="btns searchs" @click="search">
          {{ t('search') }}
        </div>
        <div class="btns reset" @click="reset" style="margin-top: 5px">{{ t('reset') }}</div>
        <a-col :span="24">
          <a-row :gutter="[15, 15]">
            <a-col :xs="24" :sm="24" :lg="6" :xl="6" :xxl="6" v-for="(item, index) in dataList" :key="index">
              <template v-if="!loadRef">
                <a-card hoverable>
                  <template #cover>
                    <img v-if="item.coverUrl" alt="example" :src="item.coverUrl" />
                    <img v-else alt="example" src="@/assets/images/calendar/1.jpg" />
                  </template>
                  <div class="userBox">
                    <div class="leftBox">
                      <img :src="checkPic(item.influencerBo.avatarUrl, 32, 32)" alt="" />
                    </div>
                    <div class="rightBox">
                      <div class="names">{{ item.influencerBo.lastName }}</div>
                      <div class="nums">
                        <template v-if="locale == 'zh_CN'">
                          本场直播添加了
                          <span>{{ item.goodsBoList && item.goodsBoList.length }}</span>
                          个商家产品
                        </template>
                        <template v-else>
                          {{ t('addShop') }}
                          <span style="margin-left: 4px">{{ item.goodsBoList && item.goodsBoList.length }}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="goodsView boxS">
                    <div class="goodsList boxS" :style="`width:${55 * (item.goodsBoList && item.goodsBoList.length)}px;`">
                      <!--                    <p v-for="item in 9" :key="item">{{item}}-->

                      <!--                    </p>-->
                      <a-popover v-for="(goodsItem, goodsIndex) in item.goodsBoList" :key="goodsIndex">
                        <template #content>
                          <div class="flexTL">
                            <img class="enlarge" :src="goodsItem.pic" alt="" />
                            <p class="enlarge">{{ goodsItem.productName }}</p>
                            <p style="margin-top: 5px; font-size: 15px; font-weight: bold">ASIN：{{ goodsItem.sin }}</p>
                            <p style="margin-top: 5px; font-size: 15px; font-weight: bold">价格：${{ goodsItem.price }}</p>
                          </div>
                        </template>
                        <p>
                          <img :src="goodsItem.pic" alt="" />
                        </p>
                      </a-popover>
                    </div>
                  </div>
                  <div class="timeBox">
                    <span>{{ t('localTime') }}:</span>
                    <span style="color: #b5b5b5">{{ item.usBeginTime }}</span>
                  </div>
                  <div class="timeBox" style="margin: 8px 0">
                    <span>{{ t('chinaTime') }}:</span>
                    <span style="color: #b5b5b5">{{ item.cnBeginTime }}</span>
                  </div>
                  <div class="typeBox">
                    <div class="leftFont" v-if="item.liveState === 1">
                      <a-badge color="blue" />
                      {{ t('notStartLive') }}
                    </div>
                    <div class="leftFont" v-if="item.liveState === 2">
                      <a-badge color="green" status="processing" />
                      {{ t('typeLive') }}
                    </div>

                    <div class="leftFont" v-if="item.liveState === 3">
                      <a-badge color="red" />
                      {{ t('endLive') }}
                    </div>
                    <div @click="openUrl(item.liveUrl)">
                      <i class="fa fa-play-circle-o" style="color: #ff6700" aria-hidden="true"></i>
                      {{ t('watchLive') }}
                    </div>
                  </div>
                </a-card>
              </template>
              <template v-else>
                <a-space :loading="false" :active="true">
                  <a-skeleton-button :active="true" style="width: 100%; margin-bottom: 8px; height: 120px"></a-skeleton-button>
                  <a-skeleton-button :active="true" style="width: 100%; margin-bottom: 8px; height: 30px"></a-skeleton-button>
                  <a-skeleton-button :active="true" style="width: 100%; margin-bottom: 8px; height: 38px"></a-skeleton-button>
                  <a-skeleton-button :active="true" style="width: 100%; margin-bottom: 8px; height: 34px"></a-skeleton-button>
                  <a-skeleton-button :active="true" style="width: 100%; height: 34px"></a-skeleton-button>
                </a-space>
              </template>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
  // import axios from "axios";
  import { useRoute } from 'vue-router'
  import { reactive, toRefs, watch, ref } from 'vue'
  import moment from 'moment'
  import { getDayDetails, getDayInfluencer } from '../../../../api/live'
  import { checkPic } from '../../../../utils/util'
  import { useI18n } from 'vue-i18n'

  export default {
    name: 'LiveCalendarList',
    setup() {
      const loadRef = ref(true)
      const { t, locale } = useI18n()
      let route = useRoute()
      console.log(route)
      let data = reactive({
        date: [],
        queryData: {
          influencerAccountIdCode: null,
          asin: null,
          // page:1,
          // size:20,
          beginTime: route.query.time ? moment(Number(route.query.time)).format('YYYY-MM-DD') : moment(Date.now()).format('YYYY-MM-DD'),
          endTime: route.query.time ? moment(Number(route.query.time)).format('YYYY-MM-DD') : moment(Date.now()).format('YYYY-MM-DD')
        },
        dataList: [],
        influencerData: []
      })
      // if (route.query.time) {
      //   data.date = [moment(Number(route.query.time)), moment(Number(route.query.time))]
      // }else {
      //   data.date = [moment(Date.now()).format('YYYY-MM-DD'),moment(Date.now()).format('YYYY-MM-DD')];
      // }
      watch(
        () => data.date,
        e => {
          if (!e[0]) return false
          data.queryData.beginTime = moment(data.date[0]).format('YYYY-MM-DD')
          data.queryData.endTime = moment(data.date[1]).format('YYYY-MM-DD')
          getDayDetailsFn()
          getDayInfluencerFn()
        }
      )
      watch(
        () => data.queryData.influencerAccountIdCode,
        () => {
          getDayDetailsFn()
        }
      )
      getDayDetailsFn()

      function getDayDetailsFn() {
        loadRef.value = true
        getDayDetails(data.queryData, route.query.staoreId).then(res => {
          if (res.code === 200) {
            data.dataList = res.data
            setTimeout(() => {
              loadRef.value = false
            }, 1000)
          }
        })
      }

      getDayInfluencerFn()

      function getDayInfluencerFn() {
        getDayInfluencer(
          {
            beginTime: data.queryData.beginTime,
            endTime: data.queryData.endTime
          },
          route.query.staoreId
        ).then(res => {
          console.log(res)
          data.influencerData = res.data
        })
      }

      function openUrl(url) {
        window.open(url)
      }

      function changeInfluencer(event) {
        console.log(event)
        data.queryData.influencerAccountIdCode = event
      }

      function search() {
        getDayDetailsFn()
        getDayInfluencerFn()
      }

      function reset() {
        data.queryData = {
          influencerAccountIdCode: null,
          asin: null,
          beginTime: route.query.time ? moment(Number(route.query.time)).format('YYYY-MM-DD') : moment(Date.now()).format('YYYY-MM-DD'),
          endTime: route.query.time ? moment(Number(route.query.time)).format('YYYY-MM-DD') : moment(Date.now()).format('YYYY-MM-DD')
        }
        data.date = []
        search()
      }

      function panelChange(e) {
        console.log(1, e)
        data.date = e
      }

      return {
        loadRef,
        t,
        locale,
        ...toRefs(data),
        openUrl,
        changeInfluencer,
        search,
        reset,
        panelChange,
        checkPic
      }
    }
  }
</script>
<style>
  .typeBox {
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff5ec;
    border-radius: 4px;
  }
  /* .ant-input {
  border-radius: 10px;
} */
  .ant-space-align-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    border: 1px solid #eeeeee;
  }
  .ant-space-item {
    width: 100%;
  }
</style>
<style scoped lang="less">
  .conterntBox {
    min-width: 100% !important;
  }
  .leftFont {
    color: #7a7576;
  }
  .userBox {
    width: 100%;
    display: flex;
    align-items: center;
    .leftBox {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
      }
    }
    .rightBox {
      margin-left: 16px;
      .names {
        color: #131011;
        font-size: 14px;
        line-height: 20px;
      }
      .nums {
        font-size: 12px;
        line-height: 18px;
        color: #131011;
        span {
          color: #ff6700;
        }
      }
    }
  }
  ::v-deep(.ant-card-actions li) {
    width: none;
  }
  ::v-deep(.ant-card-bordered) {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    img {
      width: 100%;
      border-radius: 4px;
    }
  }
  .btns {
    height: 40px;
    width: 80px;
    border-radius: 8px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    cursor: pointer;
  }
  .searchs {
    color: #fff;
    background: #ff6700;
  }
  .reset {
    color: #101310;
    background: #d2d2d2;
  }
  .listBox {
    padding-top: 0 !important;
  }
  ::v-deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    border-radius: 10px !important;
  }
  ::v-deep(.ant-card-actions) {
    background: #fff5ec;
    border-radius: 4px;
  }
  ::v-deep(.ant-card-actions > li:not(:last-child)) {
    border: none;
  }
  p {
    margin: 0;
    padding: 0;
  }

  .min-width {
    //min-width: 1400px;
  }

  ::v-deep(.ant-card-meta-description) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 65px;
  }

  .goodsView {
    width: 100%;
    height: 60px;
    //margin-top: 15px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 5px;
    }

    .goodsList {
      height: 100%;

      p {
        width: 50px;
        height: 50px;
        background: #eceff1;
        float: left;
        margin-right: 5px;

        img {
          width: 100%;
        }
      }
    }
  }

  .fa {
    font-size: 16px;
  }
  .timeBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 12px;
      line-height: 18px;
      color: #131011;
    }
  }
  ::v-deep(.ant-card-body) {
    padding: 8px 0 0 0;
  }
  .goodsP {
    width: 100%;
    margin: 10px 0;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: #1890ff;
      font-size: 17px;
    }
  }
</style>
