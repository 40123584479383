import Clipboard from 'clipboard';
import { message } from 'ant-design-vue';

export function checkURL(URL) {
    let Expression = /http(s)?:\/\//;
    let objExp = new RegExp(Expression);
    if (objExp.test(URL) === true) {
        return true;
    } else {
        return false;
    }
}
export function openURL(URL) {
    let Expression = /http(s)?:\/\//;
    let objExp = new RegExp(Expression);
    if (objExp.test(URL) === true) {
        return URL;
    } else {
        return 'http://' + URL;
    }
}
export function copyFun(id, { text }) {
    const clipboard = new Clipboard(id, {
        text: function() {
            return text;
        }
    });
    clipboard.on('success', () => {
        message.success('复制成功');
        clipboard.destroy();
    });
    clipboard.on('error', () => {
        message.error('复制失败！');
        clipboard.destroy();
    });
}
export function cachePageParameters(data, callback) {
    if (!data) {
        if (window.sessionStorage.getItem(window.location.pathname)) {
            callback(JSON.parse(window.sessionStorage.getItem(window.location.pathname)))
        }
        callback(null)
    } else {
        window.sessionStorage.setItem(window.location.pathname, JSON.stringify(data));
    }
}
export function checkNumFn(num) {
    let str = num.toString();
    if (str.split('.').length === 1) {
        str += '.00';
        return str;
    }
    if (str.split('.').length > 1) {
        if (str.split('.')[1].length < 2) {
            str = str + '0';
        }
        return str;
    }
}
export function checkPic(url, width, height) {
    return url.replace(/{height}/, height).replace(/{width}/, width)
}
export function formatImg(path, w, h, bw, by) {
    if (!path) return null;
    return path.replace('{X}', w).replace('{Y}', h).replace('{bgX}', bw).replace('{bgY}', by).replace('{width}', w).replace('{height}', h);
}